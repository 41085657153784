import { VesselData } from 'interfaces/vessel'
import { useGetSpeedFuelEfficiencyInKnotsQuery } from 'store'
import ScatterChart from 'components/d3-charts/ScatterChart'
import ChartWrapper from 'components/chart/ChartWrapper'
import ToggleSwitch from 'components/form/ToggleSwitch'
import HexbinChart from 'components/d3-charts/HexbinChart'
import { useState } from 'react'
import Loader from 'components/Loader'

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
}

function SpeedOverGroundVsFuelConsumption({ vessel, startTime, endTime, offset }: Props) {
  const { data, isFetching } = useGetSpeedFuelEfficiencyInKnotsQuery({
    vesselId: vessel.id,
    flowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
    startTime: startTime,
    endTime: endTime,
    isSplit: vessel.main_engines[0].flowMeter[0].split,
  })

  const scatterData = Array.isArray(data)
    ? data.map((item: any) => ({
        nameX: item.nameX,
        nameY: item.nameY,
        nameZ: item.nameZ,
        timestamp: item.timestamp,
        valueX: item.valueX,
        valueY: item.valueY,
        valueZ: item.valueZ,
      }))
    : []

  const [showScatterChartSections, setShowScatterSections] = useState(true)
  const [showHexbinChartSections, setShowHexbinSections] = useState(true)

  console.log('SpeedOverGroundVsFuelConsumption data', data)

  return (
    <div className="flex flex-row gap-4">
      <ChartWrapper
        title="Speed over ground vs Fuel Consumption (Fuel efficiency as Color)"
        className="h-96"
      >
        <div className="flex flex-1 h-full rounded-lg items-center flex-col justify-center">
          {isFetching ? (
            <Loader />
          ) : (
            <>
              <ScatterChart
                data={scatterData}
                showChartSections={showScatterChartSections}
              />
              <div className="flex flex-row gap-2">
                <p>Overlay</p>
                <ToggleSwitch
                  value={showScatterChartSections}
                  onChange={() => {
                    setShowScatterSections(!showScatterChartSections)
                  }}
                ></ToggleSwitch>
              </div>
            </>
          )}
        </div>
      </ChartWrapper>
      <ChartWrapper
        title="Speed over ground vs Fuel Consumption"
        className="h-96"
      >
        <div className="flex flex-1 h-full rounded-lg items-center flex-col justify-center">
          {isFetching ? (
            <Loader />
          ) : (
            <>
              <HexbinChart
                data={scatterData}
                showChartSections={showHexbinChartSections}
              />
              <div className="flex flex-row gap-2">
                <p>Overlay</p>
                <ToggleSwitch
                  value={showHexbinChartSections}
                  onChange={() => {
                    setShowHexbinSections(!showHexbinChartSections)
                  }}
                ></ToggleSwitch>
              </div>
            </>
          )}
        </div>
      </ChartWrapper>
    </div>
  )
}

export default SpeedOverGroundVsFuelConsumption
