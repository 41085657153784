import * as d3 from 'd3'

export interface TooltipValue {
  label: string
  value: number | string
  unit?: string
  decimals?: number
}

export interface TooltipConfig<T> {
  container: HTMLDivElement
  getValues: (d: T) => TooltipValue[]
}

export class Tooltip<T = any> {
  private tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined>

  constructor(private config: TooltipConfig<T>) {
    const existingTooltip = d3.select(config.container).select<HTMLDivElement>('.tooltip')
    if (!existingTooltip.empty()) {
      this.tooltip = existingTooltip
    } else {
      this.tooltip = d3
        .select(config.container)
        .append('div')
        .attr('class', 'tooltip')
        .style('position', 'fixed')
        .style('visibility', 'hidden')
        .style('background-color', 'white')
        .style('border', '1px solid #ddd')
        .style('border-radius', '4px')
        .style('padding', '8px')
        .style('font-size', '12px')
        .style('pointer-events', 'none')
        .style('z-index', '1000')
        .style('transform', 'translate(-50%, -100%)')
    }
  }

  private formatContent(values: TooltipValue[]): string {
    return values
      .map(({ label, value, unit = '', decimals = 1 }) => {
        const formattedValue = typeof value === 'number' ? value.toFixed(decimals) : value
        return `<strong>${label}:</strong> ${formattedValue}${unit}`
      })
      .join('<br/>')
  }

  addTo(selection: d3.Selection<any, T, any, any>) {
    selection
      .on('mouseover', (event, d) => {
        const values = this.config.getValues(d)
        this.tooltip
          .style('visibility', 'visible')
          .style('left', `${event.clientX}px`)
          .style('top', `${event.clientY - 10}px`)
          .html(this.formatContent(values))
      })
      .on('mousemove', (event) => {
        this.tooltip.style('left', `${event.clientX}px`).style('top', `${event.clientY - 10}px`)
      })
      .on('mouseout', () => {
        this.tooltip.style('visibility', 'hidden')
      })

    return selection
  }
}
