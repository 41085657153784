import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { GraphQueryArgs, ScatterGraphData } from 'interfaces/graphApi'

const inKnotsGraphApi = createApi({
  reducerPath: 'inKnotsGraphApi',
  baseQuery: fakeBaseQuery(),
  keepUnusedDataFor: 600,
  endpoints(build) {
    return {
      getFuelEfficiencyInKnots: build.query<ScatterGraphData, GraphQueryArgs>({
        queryFn: async ({ vesselId, flowMeterId, startTime, endTime, isSplit }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getFuelEfficiencyInKnots')
            const result: any = await getData({ vesselId, flowMeterId, startTime, endTime, isSplit })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getPowerInKnots: build.query<ScatterGraphData, GraphQueryArgs>({
        queryFn: async ({ vesselId, shaftMeterId, startTime, endTime }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getPowerInKnots')
            const result: any = await getData({ vesselId, shaftMeterId, startTime, endTime })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getTorqueInKnots: build.query<ScatterGraphData, GraphQueryArgs>({
        queryFn: async ({ vesselId, shaftMeterId, startTime, endTime }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getTorqueInKnots')
            const result: any = await getData({ vesselId, shaftMeterId, startTime, endTime })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getRPMInKnots: build.query<ScatterGraphData, GraphQueryArgs>({
        queryFn: async ({ vesselId, shaftMeterId, startTime, endTime }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getRPMInKnots')
            const result: any = await getData({ vesselId, shaftMeterId, startTime, endTime })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getMassFlowRateInKnots: build.query<ScatterGraphData, GraphQueryArgs>({
        queryFn: async ({ vesselId, flowMeterId, startTime, endTime, isSplit }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getMassFlowRateInKnots')
            const result: any = await getData({ vesselId, flowMeterId, startTime, endTime, isSplit })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getSFOCInKnots: build.query<ScatterGraphData, GraphQueryArgs>({
        queryFn: async ({ vesselId, flowMeterId, shaftMeterId, startTime, endTime, isSplit }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getSFOCInKnots')
            const result: any = await getData({ vesselId, flowMeterId, shaftMeterId, startTime, endTime, isSplit })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getSpeedFuelEfficiencyInKnots: build.query<ScatterGraphData, GraphQueryArgs>({
        queryFn: async ({ vesselId, flowMeterId, startTime, endTime, isSplit }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getSpeedFuelEfficiencyInKnots')
            const result: any = await getData({ vesselId, flowMeterId, startTime, endTime, isSplit })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
    }
  },
})

export const { useGetMassFlowRateInKnotsQuery, useGetSpeedFuelEfficiencyInKnotsQuery } = inKnotsGraphApi
export { inKnotsGraphApi }
