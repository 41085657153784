import { endOfDay, endOfHour, endOfToday, startOfDay, subHours } from 'date-fns'
import { getTimestampWithOffset } from './getTimestampWithOffset'

export function getCustomDateTimeStamps(startTime: Date, endTime: Date, offset: number = 0) {
  const currentDate = new Date()
  const isEndTimeToday = endOfDay(endTime)?.getTime() === endOfToday().getTime()
  const endOfPreviousHourTime = endOfHour(subHours(currentDate, 1)).getTime()
  const endOfEndTimeDayTime = endOfDay(endTime)?.getTime()
  // const tzOffset = currentDate.getTimezoneOffset() * 60000
  // const tzOffset = currentDate.getTimezoneOffset() * 60000 + timezoneOffset * 3600000
  const tzOffset = getTimestampWithOffset(currentDate.getTimezoneOffset() * 60000, offset)
  return {
    startTime: startOfDay(startTime).getTime() - tzOffset,
    endTime: (isEndTimeToday ? endOfPreviousHourTime : endOfEndTimeDayTime) - tzOffset,
  }
}
