import * as d3 from 'd3'

interface ChartAxesProps {
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>
  width: number
  height: number
  margin: { top: number; right: number; bottom: number; left: number }
  xScale: d3.ScaleLinear<number, number>
  yScale: d3.ScaleLinear<number, number>
  xAxisLabel?: string
  yAxisLabel?: string
  y1AxisLabel?: string
}

export function createChartAxes({
  svg,
  width,
  height,
  margin,
  xScale,
  yScale,
  xAxisLabel = 'Speed over ground [knots]',
  yAxisLabel = 'Fuel Consumption [kg/h]',
  y1AxisLabel = 'Frequency [minutes]',
}: ChartAxesProps) {
  // Add grid lines
  svg
    .append('g')
    .attr('class', 'grid')
    .attr('transform', `translate(${margin.left},0)`)
    .style('stroke-dasharray', '3,3')
    .style('opacity', 0.2)
    .call(
      d3
        .axisLeft(yScale)
        .tickSize(-width + margin.left + margin.right)
        .tickFormat(() => '')
    )

  svg
    .append('g')
    .attr('class', 'grid')
    .attr('transform', `translate(0,${height - margin.bottom})`)
    .style('stroke-dasharray', '3,3')
    .style('opacity', 0.2)
    .call(
      d3
        .axisBottom(xScale)
        .tickSize(-height + margin.top + margin.bottom)
        .tickFormat(() => '')
    )

  // Add axes
  svg
    .append('g')
    .attr('transform', `translate(0,${height - margin.bottom})`)
    .call(d3.axisBottom(xScale))

  svg.append('g').attr('transform', `translate(${margin.left},0)`).call(d3.axisLeft(yScale).ticks(10))

  // Add axis labels
  svg
    .append('text')
    .attr('x', width / 2)
    .attr('y', height - margin.bottom / 3)
    .style('text-anchor', 'middle')
    .text(xAxisLabel)

  svg
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('x', -height / 2)
    .attr('y', margin.left / 3)
    .style('text-anchor', 'middle')
    .text(yAxisLabel)
  // Only add y1 axis label if provided
  if (y1AxisLabel) {
    svg
      .append('text')
      .attr('transform', 'rotate(90)')
      .attr('x', height / 2)
      .attr('y', -(width - margin.right / 2 + 30))
      .style('text-anchor', 'middle')
      .text(y1AxisLabel)
  }
}
