import classNames from 'classnames'
import FormGroup from 'components/form/FormGroup'
import FormLabel from 'components/form/FormLabel'
import RadioButtonGroup from 'components/form/RadioButtonGroup'
import Select, { SelectOption } from 'components/form/Select'
import ToggleSwitch from 'components/form/ToggleSwitch'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { RootState } from 'store'
import {
  EngineOption,
  changeGraph1Source,
  changeGraph1Metric,
  changeGraph1Engines,
  changeGraph1TrendlineStatus,
  changeGraph2Metric,
} from 'store/slices/analyticsSlice'

interface Props {
  sourceOptions: any[]
  engineOptions: any[]
}

function FiltersPanelBlock1({ sourceOptions, engineOptions }: Props) {
  const dispatch = useAppDispatch()
  const { graph1Filters, graph2Filters, allowTrendline, measuredBy } = useAppSelector(
    (state: RootState) => state.analytics
  )
  const block1Disabled = graph2Filters.metric?.value === 'fuelConsumed'
  const sourceOneInitialEngine: SelectOption[] = []

  useEffect(() => {
    resetSource1Engine()
  }, [graph1Filters.source])

  useEffect(() => {
    resetSource1Engine()
  }, [measuredBy])

  function resetSource1Engine() {
    dispatch(changeGraph1Metric(undefined))

    const mainEngine = engineOptions.find(({ type }: EngineOption) => type === 'm/e')
    const auxEngine = engineOptions.find(({ type }: EngineOption) => type === 'a/e')
    if (mainEngine) {
      sourceOneInitialEngine.push(mainEngine)
      dispatch(changeGraph1Engines([mainEngine]))
    } else {
      sourceOneInitialEngine.push(auxEngine)
      dispatch(changeGraph1Engines([auxEngine]))
    }
  }

  function handleSourceOneChange(value: string) {
    dispatch(changeGraph1Source(value))
  }

  function handleEnginesOneChange(selectedOptions: SelectOption[] | SelectOption) {
    const newValues = selectedOptions.constructor === Array ? selectedOptions : [selectedOptions]
    dispatch(changeGraph1Engines(newValues))
  }

  function handleMetricOneChange(selectedOption: any) {
    if (selectedOption?.value === 'fuelConsumed') {
      dispatch(changeGraph2Metric(null))
    }
    dispatch(changeGraph1Metric(selectedOption))
  }

  function handleTrendlineOneToggle(value: boolean) {
    dispatch(changeGraph1TrendlineStatus(value))
  }

  return (
    <div className="filter-block flex-1 p-4 pb-0 max-lg:border-r lg:border-b border-grayOutlineColor relative">
      {block1Disabled && <div className="absolute z-10 w-full h-full bg-white top-0 left-0 opacity-75"></div>}
      <header className="filter-block-header flex p-1 leading-18 mb-4">
        <div className="h-[1.125rem] w-[1.125rem] mr-[0.45rem] bg-darkBlue text-white text-[0.875rem] text-center">
          1
        </div>
        <div className="text-sm lg:text-lg">Data source</div>
      </header>
      <div className="filter-block-content">
        <FormGroup className="flex justify-between">
          <RadioButtonGroup
            options={sourceOptions}
            onChange={handleSourceOneChange}
            initialValue={sourceOptions[0].name}
            inline
            hideCheck
            className="text-bold text-center"
            textCenter
            textStrong
          />
        </FormGroup>
        {graph1Filters.source === 'engines' && (
          <FormGroup>
            <FormLabel>Selected engines</FormLabel>
            <Select
              options={engineOptions}
              placeholder="Select an engine"
              onChange={handleEnginesOneChange}
              defaultValue={sourceOneInitialEngine}
              value={graph1Filters.selectedEngines}
              isMulti={measuredBy === 'time'}
            />
          </FormGroup>
        )}
        <FormGroup>
          <FormLabel>Metrics</FormLabel>
          <Select
            options={graph1Filters.metricOptions}
            onChange={handleMetricOneChange}
            value={graph1Filters.metric || ''}
            placeholder="Select a metric"
            disabled={graph1Filters.metricOptions.length === 0}
            isClearable
          />
        </FormGroup>
        <FormGroup>
          <ToggleSwitch
            size="sm"
            value={graph1Filters.drawTrendline}
            onChange={handleTrendlineOneToggle}
            disabled={!allowTrendline}
            label="Draw trendline"
            className={classNames('items-center text-lg font-extrabold', { 'opacity-30': !allowTrendline })}
          />
        </FormGroup>
        {/* <FormGroup>
    <ToggleSwitch
      size="sm"
      value={value}
      onChange={setValue}
      label="Toggle to save your current selections as the default
      settings for future reports."
      className="items-center text-[0.75rem] leading-3 text-grayText"
    />
  </FormGroup> */}
      </div>
    </div>
  )
}

export default FiltersPanelBlock1
