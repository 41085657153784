import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import RootLayout from '../layouts/RootLayout'
import HeaderLayout from '../layouts/HeaderLayout'
import NavbarLayout from '../layouts/NavbarLayout'
import Login from './Login'
import ChangePassword from './ChangePassword'
import Users from './Users'
import Vessels from './Vessels'
import DataAnalytics from './data-analytics/DataAnalytics'
import NotFound from './NotFound'
import PrivateRoute from '../provider/PrivateRoute'
import CreateNewUser from './CreateNewUser'
import WrongScreenSize from './WrongScreenSize'
import UserDetails from './UsersDetails'
import HelpCentre from './HelpCentre'
import Settings from './Settings'
import PerformanceOverview from './performance-overview/PerformanceOverview'
import { changeInitiallyRequestedUrl } from 'store'
import { useAppDispatch } from 'hooks/useReduxHooks'
import { AuthUser } from 'interfaces/auth'
import VesselComparison from './vessel-comparison/VesselComparison'
import Performance from './performance/Performance'
import Overview from './performance/overview/Overview'
import Calculations from './performance/calculations/Calculations'
import ImportPage from './ImportPage'
import Analytics from './analytics/Analytics'
import VesselReport from './analytics/vessel-report/VesselReport'
import TripReport from './analytics/trip-report/TripReport'
import CaptainReport from './analytics/captain-report/CaptainReport'

function AppNavigator({ user }: { user: AuthUser | null }) {
  const [narrowScreen, setNarrowScreen] = useState<boolean>(false)
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const requestedPath = location.pathname
    dispatch(changeInitiallyRequestedUrl(requestedPath))
  }, [])

  useEffect(() => {
    const updateScreenStatus = () => {
      if (window.innerWidth < 600) {
        setNarrowScreen(true)
      } else {
        setNarrowScreen(false)
      }
    }

    window.addEventListener('resize', updateScreenStatus)

    return () => window.removeEventListener('resize', updateScreenStatus)
  }, [])

  return (
    <>
      {narrowScreen ? (
        <WrongScreenSize />
      ) : (
        <Routes>
          <Route
            path="/"
            element={<RootLayout />}
          >
            <Route
              path="login"
              element={<Login />}
            />
            <Route
              path="/reset-password/"
              element={<ChangePassword />}
            />
          </Route>

          <Route
            path="/"
            element={
              <PrivateRoute>
                <HeaderLayout />
              </PrivateRoute>
            }
          >
            <Route
              path="/import/:vesselId"
              element={<ImportPage />}
            />
            <Route
              path="/data-analytics/:vesselId"
              element={<DataAnalytics />}
            />
            <Route
              path="/"
              element={<NavbarLayout admin={user?.isAdmin || false} />}
            >
              <Route
                index
                path="/"
                element={<Vessels />}
              />
              <Route
                path="/performance/:vesselId"
                element={<Performance />}
              >
                <Route
                  path="overview"
                  element={<Overview />}
                />
                <Route
                  path="calculations"
                  element={<Calculations />}
                />
              </Route>
              <Route
                path="/analytics/:vesselId"
                element={<Analytics />}
              >
                <Route
                  path="vessel-report"
                  element={<VesselReport />}
                />
                <Route
                  path="trip-report"
                  element={<TripReport />}
                />
                <Route
                  path="captain-report"
                  element={<CaptainReport />}
                />
              </Route>
              <Route
                path="/performance-overview/:vesselId"
                element={<PerformanceOverview />}
              />
              <Route
                path="/vessel-comparison"
                element={<VesselComparison />}
              />
              <Route
                path="/users"
                element={<Users />}
              />
              <Route
                path="/user-details"
                element={<UserDetails />}
              />
              <Route
                path="/users/create-user"
                element={<CreateNewUser />}
              />
              <Route
                path="/help-centre"
                element={<HelpCentre />}
              />
              <Route
                path="/settings"
                element={<Settings />}
              />
            </Route>
          </Route>
          <Route
            path="*"
            element={<NotFound />}
          />
        </Routes>
      )}
    </>
  )
}
export default AppNavigator
