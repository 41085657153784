import { getUTCDateString } from 'shared/date/getUTCDateString'
import { getUTCTimeString } from 'shared/date/getUTCTimeString'

export default function getLineChartOptions(
  scales: any,
  timestamps: any,
  vesselLastUpdated: number,
  id: string = 'line-chart',
  hideY1Legend: boolean = false
) {
  return {
    animation: false,
    normalized: true,
    maintainAspectRatio: false,
    chartInstanceId: id,
    devicePixelRatio: 2,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales,
    plugins: {
      tooltip: {
        usePointStyle: true,
        callbacks: {
          title: function (context: any) {
            const dateTime = timestamps[context[0].dataIndex]
            const date = new Date(dateTime * 1000)
            return `${getUTCDateString(date)} ${getUTCTimeString(date)}`
          },
          label: function (context: any) {
            const segmentTimestamp = timestamps[context.dataIndex]
            return segmentTimestamp > vesselLastUpdated
              ? 'Data not available'
              : `${context.dataset.label}: ${context.raw}`
          },
        },
      },
      legend: {
        position: 'bottom',
        fullSize: true,
        labels: {
          boxWidth: 12,
          boxHeight: 12,
          usePointStyle: true,
          pointStyle: 'rectRounded',
          padding: 20,
          font: {
            size: 16,
          },
          filter: function (legendItem: any) {
            if (!hideY1Legend) return true
            // Hide legend for the dataset linked to y1
            return legendItem.datasetIndex === 0
          },
        },
      },
    },
  }
}
