import { Outlet, useLocation } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  changeSelectedVessel,
  changeDateRange,
  RootState,
  useGetVesselByIdQuery,
  changeChartIsRendering,
  useLazyExportVesselDataQuery,
} from 'store'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import ButtonGroup, { ButtonGroupItem } from 'components/ButtonGroup'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'
import classNames from 'classnames'
import DatePicker from 'components/form/DatePicker'
import { getCustomDateTimeStamps } from 'shared/date/getCustomDateTimeStamps'
import getTimePeriodData from 'shared/date/getTimePeriodData'
import VesselInfo from 'components/VesselInfo'
import Dropdown from 'components/Dropdown'
import { Chart as ChartJS } from 'chart.js'
import { store, AppDispatch, changeLineChartAreaWidth, changePerformanceOverviewAlignmentDone } from 'store'
import { ChartAlignmentData } from 'store/slices/lineChartSlice'
import Button from 'components/Button'
import { ReactComponent as DataAlalyticsIcon } from 'assets/icons/pieChart.svg'

const dispatch: AppDispatch = store.dispatch

const chartAreaPlugin = {
  id: 'chartAreaPlugin',
  beforeUpdate(chart: any) {
    if (chart.config._config.type !== 'line') {
      return
    }
    // Track if the chart is in its initial render
    if (!chart._pluginsMeta) {
      chart._pluginsMeta = { initialized: true } // Custom property for tracking
    }
  },
  afterDraw(chart: any) {
    if (chart.config._config.type !== 'line') {
      return
    }
    // Ignore mouseover events by checking interaction state
    if (chart._active && chart._active.length > 0) {
      return // Skip plugin logic during interactions (e.g., mouseover)
    }

    const chartInstanceId = chart.config._config.options.chartInstanceId
    if (!chartInstanceId) return

    const [prefix, chartName] = chartInstanceId.split('-')

    if (prefix !== 'Performance') return
    const chartArea = chart.chartArea
    if (chart._pluginsMeta.initialized) {
      dispatch(changeLineChartAreaWidth({ chartName, chartAreaWidth: chartArea.width }))

      // You could reset or update the meta property if needed.
      chart._pluginsMeta.initialized = false
    }
  },
}

ChartJS.register(chartAreaPlugin)

function Performance() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { vesselId } = useParams()
  const datepickerContainer = useRef<HTMLInputElement>(null)
  const [selectedPeriod, setSelectedPeriod] = useState<string>('sevenDays')
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
  const [isCustomRange, setIsCustomRange] = useState<boolean>(false)
  const [currentDownload, setCurrentDownload] = useState<string | null>(null)
  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const { data: vessel, isFetching } = useGetVesselByIdQuery(vesselId || '', { skip: !vesselId })
  const [launchExport, exportResult, lastPromiseInfo] = useLazyExportVesselDataQuery()
  const user = useAppSelector((state: RootState) => {
    return state.auth.user
  })
  const { startTime, endTime } = useAppSelector((state: RootState) => {
    return state.performance.dateRange
  })
  const { offset } = useAppSelector((state: RootState) => {
    return state.app.selectedTimezone
  })
  const performanceOverviewAlignment = useAppSelector((state: RootState) => {
    return state.lineChart.performanceOverviewAlignment
  })

  const dataAnalyticsAllowed = false

  const keepDatePickerAlive = isCustomRange || showDatePicker

  const tabButtons: ButtonGroupItem[] = [
    { label: 'Overview', value: 'overview' },
    { label: 'Calculations', value: 'calculations' },
  ]

  const rangeButtons: ButtonGroupItem[] = [
    { label: 'Custom', value: 'custom-suspend', children: <CalendarIcon /> },
    { label: 'Last 7 days', value: 'sevenDays' },
    { label: 'Last month', value: 'month' },
    { label: 'Last 3 months', value: '3months' },
  ]

  const exportOptions = [
    {
      label: 'Excel',
      value: 'excel',
    },
    {
      label: 'CSV',
      value: 'csv',
    },
    {
      label: 'JSON',
      value: 'json',
    },
  ]

  const currentPathSegments = location.pathname.split('/')
  const selectedTab = currentPathSegments[currentPathSegments.length - 1]

  useEffect(() => {
    if (vessel) {
      dispatch(changeSelectedVessel(vessel))
    }
  }, [vessel])

  useEffect(() => {
    if (user?.isDemo) {
      const demoTime = getTimePeriodData('demoTime', offset)
      if (!demoTime) return
      dispatch(changeDateRange(demoTime))
    } else {
      const lastWeek = getTimePeriodData('sevenDays', offset)
      if (!lastWeek) return
      dispatch(changeDateRange(lastWeek))
    }
  }, [user])

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!datepickerContainer.current) return
      if (!datepickerContainer.current.contains(event.target as any)) {
        setShowDatePicker(false)
      }
    }

    document.addEventListener('click', handler, true)

    return () => {
      document.removeEventListener('click', handler, true)
    }
  }, [])

  useEffect(() => {
    if (!exportResult.data) return
    if (currentDownload === exportResult.data) return
    setCurrentDownload(exportResult.data)
    const link = document.createElement('a')
    link.href = exportResult.data
    link.setAttribute('download', 'exported-data')
    document.body.appendChild(link)

    link.click()
    link.remove()
    setExportLoading(false)
  }, [exportResult])

  useEffect(() => {
    if (typeof offset !== 'number') return
    if (selectedPeriod === 'custom-suspend') {
      const startTimeDate = new Date(startTime)
      const endTimeDate = new Date(endTime)
      const selectedPeriodData = getCustomDateTimeStamps(startTimeDate, endTimeDate, offset)
      dispatch(changeChartIsRendering(true))
      dispatch(changeDateRange(selectedPeriodData))
    } else {
      updateRange(selectedPeriod, offset)
    }
  }, [offset])

  useEffect(() => {
    const allUpdated = Object.values(performanceOverviewAlignment).every((child) => child.chartAreaWidth > 0)
    if (!allUpdated) return

    dispatch(changePerformanceOverviewAlignmentDone(true))
    const itemWithSmallestWidth = Object.entries(performanceOverviewAlignment).reduce(
      (acc, [key, value]) => (value.chartAreaWidth < acc.value ? { key, value: value.chartAreaWidth } : acc),
      { key: '', value: Infinity }
    )

    const adjustmentsNecessary = Object.entries(performanceOverviewAlignment).reduce((acc, [key, value]) => {
      acc[key] = { chartAreaWidth: value.chartAreaWidth - itemWithSmallestWidth.value }
      return acc
    }, {} as any)

    Object.entries(adjustmentsNecessary).forEach(([key, value]) => {
      const alignmentData = value as ChartAlignmentData
      const element = document.querySelector(`.${key}`) as HTMLDivElement | null
      if (element && alignmentData.chartAreaWidth > 0) {
        element.style.paddingRight = `${alignmentData.chartAreaWidth - 10}px`
      }
    })
  }, [performanceOverviewAlignment])

  function updateSelectedPeriod(value: string) {
    if (typeof offset !== 'number') return
    setSelectedPeriod(value)
    if (value === 'custom-suspend') {
      setShowDatePicker(true)
      return
    } else {
      setIsCustomRange(false)
    }
    updateRange(value, offset)
  }

  function updateRange(value: string, offset: number) {
    const selectedPeriodData = getTimePeriodData(value, offset)
    dispatch(changeChartIsRendering(true))
    dispatch(changeDateRange(selectedPeriodData))
  }

  function handleTabChange(value: string) {
    navigate(`/performance/${vesselId}/${value}`)
  }

  function applyCustomDateRange({ startTime, endTime }: { startTime: Date | null; endTime: Date | null }) {
    setShowDatePicker(false)
    if (startTime && endTime) {
      setIsCustomRange(true)
      const selectedPeriodData = getCustomDateTimeStamps(startTime, endTime, offset)
      dispatch(changeChartIsRendering(true))
      dispatch(changeDateRange(selectedPeriodData))
    }
  }

  function hideDatePicker() {
    setShowDatePicker(false)
  }

  function handleExportSelect(option: any) {
    if (!vesselId || !user?.companyId) return
    setExportLoading(true)
    launchExport({
      companyId: user?.companyId,
      vesselId: vesselId,
      startTime: startTime,
      endTime: endTime,
      format: option.value,
    })
  }

  return (
    <>
      <ContentWrapper>
        <div className="mb-10">
          <VesselInfo
            vessel={vessel || null}
            isLoading={isFetching}
          >
            {dataAnalyticsAllowed && (
              <Button
                secondary
                onClick={() => navigate(`/analytics/${vessel?.id}/vessel-report`)}
                disabled={!vessel}
              >
                <DataAlalyticsIcon className="w-4 h-4 mr-2 fill-pure-white" />
                Data analytics
              </Button>
            )}
          </VesselInfo>
        </div>
        <div className="flex justify-between">
          <ButtonGroup
            buttons={tabButtons}
            value={selectedTab}
            onChange={handleTabChange}
            selectedColorName="bg-new-blue"
          />
          <div className="flex gap-4 relative">
            <ButtonGroup
              buttons={rangeButtons}
              value="sevenDays"
              activeButton={isCustomRange ? 'custom-suspend' : 'sevenDays'}
              onChange={updateSelectedPeriod}
              disabled={user?.isDemo || isFetching}
            />
            {keepDatePickerAlive && (
              <div
                ref={datepickerContainer}
                className={classNames('absolute top-12 right-0 z-20', { hidden: !showDatePicker })}
              >
                <DatePicker
                  onApply={applyCustomDateRange}
                  onCancel={hideDatePicker}
                  initialStartDate={new Date(startTime)}
                  initialEndDate={new Date(endTime)}
                />
              </div>
            )}

            <Dropdown
              dropdownTitle="Export data"
              options={exportOptions}
              onSelect={handleExportSelect}
              disabled={isFetching || exportLoading}
              loading={exportLoading}
            />
          </div>
        </div>
        <Outlet />
      </ContentWrapper>
    </>
  )
}

export default Performance
