import FormGroup from 'components/form/FormGroup'
import RadioButtonGroup from 'components/form/RadioButtonGroup'
import { EngineOption, MeasuredByValue, changeMeasuredBy } from 'store/slices/analyticsSlice'
import { Auxiliary, Engine, VesselData } from 'interfaces/vessel'
import FiltersPanelBlock1 from './filters-panel/FiltersPanelBlock1'
import FiltersPanelBlock2 from './filters-panel/FiltersPanelBlock2'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import Loader from 'components/Loader'
import { RootState } from 'store'

interface Props {
  vessel: VesselData | null
  vesselLoading: boolean
}

function FiltersPanel({ vessel, vesselLoading }: Props) {
  const dispatch = useAppDispatch()
  const { measuredBy } = useAppSelector((state: RootState) => state.analytics)

  const measureByOptions = [
    {
      name: 'time',
      label: 'Time',
      description: '[hours]',
    },
    {
      name: 'sog',
      label: 'Speed over ground',
      description: '[knots]',
    },
    {
      name: 'power',
      label: 'Power',
      description: '[kW]',
    },
  ]

  const sourceOptions = [
    {
      name: 'engines',
      label: 'Engines',
    },
    {
      name: 'other',
      label: 'Other',
    },
  ]

  const engineOptions: EngineOption[] = []
  if (vessel) {
    engineOptions.push(...createEngineOptions(vessel))
  }

  function createEngineOptions(vessel: VesselData) {
    const options: EngineOption[] = []
    vessel.main_engines.forEach((item: Engine) => {
      const optionData: EngineOption = {
        type: 'm/e',
        value: '',
        label: '',
        flowMeterId: item.flowMeter[0].sensorId,
        shaftMeterId: item.shaftMeter[0].sensorId,
        isSplit: item.flowMeter[0].split || false,
      }
      if (vessel.main_engines.length > 1) {
        optionData.value = `m/e${item.engineId}`
        optionData.label = `M/E${item.engineId}`
      } else {
        optionData.value = 'm/e'
        optionData.label = 'M/E'
      }
      options.push(optionData)
    })
    if (measuredBy === 'time') {
      if (vessel.auxiliary_engines) {
        vessel.auxiliary_engines.forEach((item: Auxiliary) => {
          const optionData: EngineOption = {
            type: 'a/e',
            value: '',
            label: '',
            flowMeterId: item.flowMeter[0].sensorId,
            isSplit: item.flowMeter[0]?.split || false,
          }
          if (vessel.auxiliary_engines && vessel.auxiliary_engines.length > 1) {
            optionData.value = `a/e${item.engineId}`
            optionData.label = `A/E${item.engineId}`
          } else {
            optionData.value = 'a/e'
            optionData.label = 'A/E'
          }
          options.push(optionData)
        })
      }
    }
    return options
  }

  function handleMeasuredByChange(value: string) {
    dispatch(changeMeasuredBy(value as MeasuredByValue))
  }

  return (
    <div className="chart-filters w-full lg:w-[285px] xl:w-[352px]">
      {vesselLoading ? (
        <div className="w-full flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="max-lg:flex w-full justify-stretch">
            <FiltersPanelBlock1
              sourceOptions={sourceOptions}
              engineOptions={engineOptions}
            />
            <FiltersPanelBlock2
              sourceOptions={sourceOptions}
              engineOptions={engineOptions}
            />
          </div>
          <div className="filter-block p-4 pb-0 border-t border-b border-grayOutlineColor">
            <header className="filter-block-header flex p-1 leading-18 mb-4">
              <div className="text-sm lg:text-lg">Measured by</div>
            </header>
            <div className="filter-block-content">
              <FormGroup className="flex justify-between">
                <RadioButtonGroup
                  options={measureByOptions}
                  onChange={handleMeasuredByChange}
                  initialValue={measureByOptions[0].name}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FiltersPanel
