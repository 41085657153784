import {
  Chart as ChartJS,
  LinearScale,
  LineElement,
  Legend,
  CategoryScale,
  Filler,
  Tooltip as ChartTooltip,
} from 'chart.js'
import ChartWrapper from 'components/chart/ChartWrapper'
import Loader from 'components/Loader'
import NoData from 'components/utility/NoData'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { Scales } from 'interfaces/chart'
import { VesselData } from 'interfaces/vessel'
import { useEffect, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { getLineChartDataset } from 'shared/chart/getLineChartDataset'
import getLineChartOptions from 'shared/chart/getLineChartOptions'
import getLineChartScaleX from 'shared/chart/getLineChartScaleX'
import getLineChartScaleY from 'shared/chart/getLineChartScaleY'
import { changeChartIsRendering, RootState, useGetTotalFuelEfficiencyInTimeQuery } from 'store'
import lineChartWorker from 'workers/lineChartWorker'

ChartJS.register(LinearScale, LineElement, CategoryScale, Legend, ChartTooltip, Filler)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
}

function FuelEfficiency({ vessel, startTime, endTime, offset }: Props) {
  const dispatch = useAppDispatch()
  const [lineData, setLineData] = useState<any>(null)
  const dataAvailable = true // TODO: check if data is available (probably need changes in backend)
  const chartIsRendering = useAppSelector((state: RootState) => state.app.chartIsRendering)
  const lineChartTickCount = useAppSelector((state: RootState) => state.app.lineChartTickCount)
  const chartTitle = 'Fuel Efficiency [kg/nm]'

  const { data, isFetching } = useGetTotalFuelEfficiencyInTimeQuery({
    vesselId: vessel.id,
    allEngineFlowMeters: [vessel.main_engines[0].flowMeter[0]],
    startTime: startTime,
    endTime: endTime,
  })

  let chartDataAndOptions = null
  const vesselLastUpdated = vessel?.last_updated?._seconds || 0

  if (lineData) {
    const { xlabels, timestamps, dataset1Values, dataset2Values } = lineData

    const datasets: any = []
    if (dataset1Values?.length) {
      const chartLabel = chartTitle
      datasets.push(
        getLineChartDataset(
          chartLabel,
          dataset1Values,
          timestamps,
          vesselLastUpdated,
          '#118F1E',
          'y',
          'rgba(17, 143, 30, 0.2)'
        )
      )
    }

    const scales: Scales = {}
    if (xlabels?.length) {
      scales.x = getLineChartScaleX(xlabels, lineChartTickCount)
      if (dataset1Values?.length) {
        const chartLabel = chartTitle
        scales.y = getLineChartScaleY(chartLabel)
      }
    }

    chartDataAndOptions = {
      chartData: {
        labels: Array.from({ length: dataset1Values.length || dataset2Values.length }, () => ''),
        datasets,
      },
      chartOptions: getLineChartOptions(scales, timestamps, vesselLastUpdated, 'Analytics-FuelEfficiency', true) as any,
    }
  }

  //   console.log('chartDataAndOptions', chartDataAndOptions)

  const loading = chartIsRendering || isFetching || !chartDataAndOptions

  useEffect(() => {
    setLineData(null)
  }, [startTime, endTime])

  useEffect(() => {
    if (isFetching) return
    const worker = new Worker(lineChartWorker)
    worker.postMessage({ graph1: data, graph2: null, tzOffset: offset })
    worker.onmessage = (e: MessageEvent) => {
      setLineData(e.data)
      dispatch(changeChartIsRendering(false))
    }
    return () => {
      worker.terminate()
    }
  }, [data])

  //   console.log('FuelEfficiency data', data)

  return (
    <ChartWrapper
      title={chartTitle}
      // FuelEfficiency in className is for the right side alignment feature, if necessary
      // It must match the id passed into getLineChartOptions utility method
      className="h-96 FuelEfficiency"
    >
      {loading || !chartDataAndOptions ? (
        <Loader />
      ) : !dataAvailable ? (
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      ) : (
        <Chart
          options={chartDataAndOptions.chartOptions}
          type="line"
          data={chartDataAndOptions.chartData}
        ></Chart>
      )}
    </ChartWrapper>
  )
}

export default FuelEfficiency
