import * as d3 from 'd3'

interface ColorLegendConfig {
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>
  width: number
  height: number
  margin: { top: number; right: number; bottom: number; left: number }
  colorScale: d3.ScaleSequential<string>
  title?: string
  gradientColors?: string[]
  useCustomGradient?: boolean
}

export function createColorLegend({
  svg,
  width,
  height,
  margin,
  colorScale,
  title,
  gradientColors,
  useCustomGradient,
}: ColorLegendConfig) {
  const gradientId = `gradient-${Math.random().toString(36).substr(2, 9)}`
  const legendWidth = 20
  const legendHeight = height - margin.top - margin.bottom
  const tickLength = 6
  const tickPadding = 3
  const legendOffset = 20

  const legendScale = d3.scaleLinear().domain(colorScale.domain()).range([legendHeight, 0]).nice()

  const legend = svg.append('g').attr('transform', `translate(${width - margin.right + legendOffset},${margin.top})`)

  // Create gradient
  const gradient = svg
    .append('defs')
    .append('linearGradient')
    .attr('id', gradientId)
    .attr('x1', '0%')
    .attr('y1', '100%')
    .attr('x2', '0%')
    .attr('y2', '0%')

  if (gradientColors && gradientColors.length > 1 && useCustomGradient) {
    gradient.append('stop').attr('offset', '0%').attr('stop-color', gradientColors[0])
    gradient.append('stop').attr('offset', '50%').attr('stop-color', gradientColors[1])
    gradient.append('stop').attr('offset', '100%').attr('stop-color', gradientColors[2])
  } else {
    const stops = d3.range(0, 1.01, 0.1)
    gradient
      .selectAll('stop')
      .data(stops)
      .enter()
      .append('stop')
      .attr('offset', (d) => `${d * 100}%`)
      .attr('stop-color', (d) => {
        const value = d3.interpolate(colorScale.domain()[0], colorScale.domain()[1])(d)
        return colorScale(value)
      })
  }

  // Add gradient rectangle
  legend
    .append('rect')
    .attr('width', legendWidth)
    .attr('height', legendHeight)
    .style('fill', `url(#${gradientId})`)
    .style('stroke', '#000')
    .style('stroke-width', '1px')

  // Add ticks and labels
  const ticks = legendScale.ticks(10)
  legend
    .selectAll('.tick')
    .data(ticks)
    .enter()
    .append('g')
    .attr('class', 'tick')
    .attr('transform', (d) => `translate(0,${legendScale(d)})`)
    .call((g) => {
      g.append('line')
        .attr('x1', legendWidth)
        .attr('x2', legendWidth + tickLength)
        .attr('stroke', '#000')

      g.append('text')
        .attr('x', legendWidth + tickLength + tickPadding)
        .attr('dy', '0.32em')
        .style('font-size', '10px')
        .text((d) => d.toFixed(0))
    })

  // Add title if provided
  if (title) {
    legend
      .append('text')
      .attr('x', -legendHeight / 2)
      .attr('y', -25)
      .attr('transform', 'rotate(-90)')
      .style('text-anchor', 'middle')
      .style('font-size', '12px')
      .text(title)
  }

  return legend
}
