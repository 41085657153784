import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ContentWrapper from 'components/layout/ContentWrapper'
import { RootState, useGetVesselByIdQuery } from 'store'
import SingleDataMetrics from '../../components/performance-overview/SingleDataMetrics'
import TotalFuelConsumed from '../../components/performance-overview/TotalFuelConsumed'
import PageHeading from 'components/typography/PageHeading'
import { useAppSelector } from 'hooks/useReduxHooks'
import VesselInfoCompact from 'components/VesselInfoCompact'
import ButtonGroup from 'components/ButtonGroup'
import { PerformanceOverviewPeriod } from 'interfaces/vessel'
import TotalCO2Emitted from '../../components/performance-overview/TotalCO2Emitted'
import Button from 'components/Button'
import { ReactComponent as DataAlalyticsIcon } from 'assets/icons/pieChart.svg'
import getTimePeriodData from 'shared/date/getTimePeriodData'

function PerformanceOverview() {
  const navigate = useNavigate()
  const { vesselId } = useParams()
  const { data: vessel, isFetching } = useGetVesselByIdQuery(vesselId || '', { skip: !vesselId })
  const [startTime, setStartTime] = useState(0)
  const [endTime, setEndTime] = useState(0)
  const [selectedPeriod, setSelectedPeriod] = useState<PerformanceOverviewPeriod>('previous_week')

  const user = useAppSelector((state: RootState) => {
    return state.auth.user
  })

  useEffect(() => {
    if (user?.isDemo) {
      const demoTime = getTimePeriodData('demoTime')
      if (!demoTime) return
      setStartTime(demoTime.startTime)
      setEndTime(demoTime.endTime)
    } else {
      const lastWeek = getTimePeriodData('previous_week')
      if (!lastWeek) return
      setStartTime(lastWeek.startTime)
      setEndTime(lastWeek.endTime)
    }
  }, [user])

  function updateRange(value: PerformanceOverviewPeriod) {
    setSelectedPeriod(value)

    const selectedPeriodData = getTimePeriodData(value)
    setStartTime(selectedPeriodData?.startTime || 0)
    setEndTime(selectedPeriodData?.endTime || 0)
  }

  const rangeButtons = [
    { label: 'Last week', value: 'previous_week' },
    { label: 'Last month', value: 'previous_month' },
    { label: 'Last 3 months', value: 'previous_3_months' },
  ]

  return (
    <ContentWrapper>
      <div className="flex justify-between mb-6">
        <PageHeading>Performance overview</PageHeading>
        <div className="flex gap-4 items-center">
          <ButtonGroup
            buttons={rangeButtons}
            value="previous_week"
            onChange={updateRange}
            disabled={user?.isDemo || isFetching}
          />
          <Button
            secondary
            onClick={() => navigate(`/data-analytics/${vessel?.id}`)}
            disabled={!vessel}
          >
            <DataAlalyticsIcon className="w-4 h-4 mr-2 fill-pure-white" />
            Data analytics
          </Button>
        </div>
      </div>

      <div className="flex max-lg:flex-col gap-4 mt-6 pb-6">
        <div className="max-lg:grid grid-cols-2 grid-rows-2 lg:flex lg:flex-col gap-6 lg:gap-9 lg:w-1/3 mb-4 lg:mr-6 xl:mr-8 lg+:w-[300px] 2xl:mr-10">
          <VesselInfoCompact
            vessel={vessel || null}
            isLoading={isFetching}
          />
          <SingleDataMetrics
            vessel={vessel || null}
            selectedPeriod={selectedPeriod}
          />
        </div>

        <div className="lg:w-2/3 lg+:flex-1">
          {startTime && endTime && (
            <div className="mb-8">
              <TotalFuelConsumed
                vessel={vessel || null}
                startTime={startTime}
                endTime={endTime}
                offset={0}
              />
            </div>
          )}

          {startTime && endTime && (
            <TotalCO2Emitted
              vessel={vessel || null}
              startTime={startTime}
              endTime={endTime}
              offset={0}
            />
          )}
        </div>
      </div>
    </ContentWrapper>
  )
}

export default PerformanceOverview
