import { RootState } from 'store'
import { useAppSelector } from 'hooks/useReduxHooks'
import MissedSavings from '../components/MissedSavings'
import SpeedOverGroundVsFuelConsumption from '../components/SpeedOverGroundVsFuelConsumption'
import FuelEfficiency from './components/FuelEfficiency'

function VesselReport() {
  const { startTime, endTime } = useAppSelector((state: RootState) => {
    return state.performance.dateRange
  })
  const { offset } = useAppSelector((state: RootState) => {
    return state.app.selectedTimezone
  })
  const { selectedVessel } = useAppSelector((state: RootState) => {
    return state.performance
  })

  return (
    <div className="flex flex-col gap-4 pb-6">
      <div className="mt-4">
        {selectedVessel && (
          <div className="mb-8">
            <MissedSavings />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <FuelEfficiency
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <SpeedOverGroundVsFuelConsumption
              vessel={selectedVessel}
              startTime={startTime}
              endTime={endTime}
              offset={offset || 0}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default VesselReport
