import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SelectOption } from 'components/form/Select'
import getDropdownOptions from 'pages/data-analytics/helpers/getDropdownOptions'

type DataSource = 'engines' | 'other'

type MeasuredByValue = 'time' | 'sog' | 'power' | 'engineLoad'
type MetricsValue =
  | 'shaftPower'
  | 'torque'
  | 'shaftSpeed'
  | 'massFlowRate'
  | 'density'
  | 'temperature'
  | 'volumeFlowRate'
  | 'fuelConsumed'
  | 'sfoc'
  | 'sog'
  | 'flowRate'
  | 'fuelEfficiency'

type EngineType = 'm/e' | 'a/e'
interface EngineOption {
  value: string
  label: string
  type: EngineType
  flowMeterId?: number
  shaftMeterId?: number
  isSplit: boolean
}

interface MetricsOption extends SelectOption {
  value: MetricsValue
  label: string
  description: string
}

interface SourceFilters {
  source: DataSource | undefined
  selectedEngines: EngineOption[]
  metricOptions: MetricsOption[]
  metric: MetricsOption | undefined
  drawTrendline: boolean
}

interface AnalyticsState {
  graph1Filters: SourceFilters
  graph2Filters: SourceFilters
  measuredBy: MeasuredByValue
  allowTrendline: boolean
  dateFilters: {
    selectedButton: string
    startTime: number
    endTime: number
  }
  graph1IsFetching: boolean
  graph2IsFetching: boolean
  graph1Error: string | null
  graph2Error: string | null
}

const initialState: AnalyticsState = {
  graph1Filters: {
    source: 'engines',
    selectedEngines: [],
    metricOptions: [],
    metric: undefined,
    drawTrendline: true,
  },
  graph2Filters: {
    source: 'engines',
    selectedEngines: [],
    metricOptions: [],
    metric: undefined,
    drawTrendline: true,
  },
  measuredBy: 'time',
  allowTrendline: false,
  dateFilters: {
    selectedButton: 'sevenDays',
    startTime: 0,
    endTime: 0,
  },
  graph1IsFetching: false,
  graph2IsFetching: false,
  graph1Error: null,
  graph2Error: null,
}

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    changeMeasuredBy(state, action: PayloadAction<MeasuredByValue>) {
      state.measuredBy = action.payload
      if (action.payload === 'time') {
        state.allowTrendline = false
        state.graph1Filters.drawTrendline = false
        state.graph2Filters.drawTrendline = false
      } else {
        state.allowTrendline = true
        state.graph1Filters.drawTrendline = true
        state.graph2Filters.drawTrendline = true
      }
      changeSource1MetricOptions(state, action)
      changeSource2MetricOptions(state, action)
      resetMetric1Values(state, action)
      resetMetric2Values(state, action)
    },
    changeGraph1Source(state, action) {
      state.graph1Filters.source = action.payload
      resetMetric1Values(state, action)
    },
    changeGraph1Metric(state, action) {
      const newMetric = action.payload
      state.graph1Filters.metric = newMetric
      // After state change
      if (newMetric?.value === 'fuelConsumed') {
        changeSource1MetricOptions(state, action)
      }
    },
    changeGraph1Engines(state, action) {
      state.graph1Filters.selectedEngines = action.payload
      // In BarChart scenarios we receive data for all sources in the same request,
      // so user should be able to change engines in the same source without reset.
      // However, if there are no engines selected, options should be reset (to no options).
      // TODO: determine based on chart type instead
      changeSource1MetricOptions(state, action)
      if (state.graph1Filters.metric?.value !== 'fuelConsumed' || state.graph1Filters.selectedEngines.length === 0) {
        resetMetric1Values(state, action)
      }
    },
    changeGraph1TrendlineStatus(state, action) {
      state.graph1Filters.drawTrendline = action.payload
    },
    changeGraph2Source(state, action) {
      state.graph2Filters.source = action.payload
      resetMetric2Values(state, action)
    },
    changeGraph2Metric(state, action) {
      const newMetric = action.payload
      state.graph2Filters.metric = newMetric
      // After state change
      if (newMetric?.value === 'fuelConsumed') {
        changeSource2MetricOptions(state, action)
      }
    },
    changeGraph2Engines(state, action) {
      state.graph2Filters.selectedEngines = action.payload
      // In BarChart scenarios we receive data for all sources in the same request,
      // so user should be able to change engines in the same source without reset.
      // However, if there are no engines selected, options should be reset (to no options).
      // TODO: determine based on chart type instead
      changeSource2MetricOptions(state, action)
      if (state.graph2Filters.metric?.value !== 'fuelConsumed' || state.graph2Filters.selectedEngines.length === 0) {
        resetMetric2Values(state, action)
      }
    },
    changeGraph2TrendlineStatus(state, action) {
      state.graph2Filters.drawTrendline = action.payload
    },
    changeDateFilters(state, action) {
      state.dateFilters = action.payload
    },
    changeErrorStatus(state, action) {
      const { graphNr, error } = action.payload
      if (graphNr === 1) {
        state.graph1Error = error?.name || null
      }
      if (graphNr === 2) {
        state.graph2Error = error?.name || null
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(changeMeasuredBy, (state, action) => {
      // changeSource1MetricOptions(state, action)
      // changeSource2MetricOptions(state, action)
    })
  },
})

const changeSource1MetricOptions: CaseReducer<AnalyticsState, PayloadAction<MeasuredByValue>> = (state, action) => {
  state.graph1Filters.metricOptions = getDropdownOptions(state.graph1Filters, state.measuredBy)
}

const changeSource2MetricOptions: CaseReducer<AnalyticsState, PayloadAction<MeasuredByValue>> = (state, action) => {
  state.graph2Filters.metricOptions = getDropdownOptions(state.graph2Filters, state.measuredBy)
}

const resetMetric1Values: CaseReducer<AnalyticsState> = (state, action) => {
  resetMetricValues(state, 1)
}

const resetMetric2Values: CaseReducer<AnalyticsState> = (state, action) => {
  resetMetricValues(state, 2)
}

function resetMetricValues(state: AnalyticsState, graphNr: number) {
  const graphFilters = graphNr === 1 ? state.graph1Filters : state.graph2Filters

  let newMetric
  if (graphFilters.source === 'other') {
    newMetric = graphFilters.metricOptions.find(({ value }: MetricsOption) => value === 'fuelEfficiency')
  } else {
    if (graphFilters.metricOptions.length === 0) {
      graphFilters.metric = undefined
    } else if (graphFilters.metricOptions.length === 1) {
      newMetric = graphFilters.metricOptions[0]
    } else {
      newMetric = graphFilters.metricOptions.find(({ value }: MetricsOption) => value === 'massFlowRate')
    }
  }
  if (newMetric) {
    graphFilters.metric = newMetric
  }
}

export const {
  changeMeasuredBy,
  changeGraph1Source,
  changeGraph1Metric,
  changeGraph1Engines,
  changeGraph1TrendlineStatus,
  changeGraph2Source,
  changeGraph2Metric,
  changeGraph2Engines,
  changeGraph2TrendlineStatus,
  changeDateFilters,
  changeErrorStatus,
} = analyticsSlice.actions
export const analyticsReducer = analyticsSlice.reducer
export type { SourceFilters, MetricsOption, EngineType, EngineOption, DataSource, MeasuredByValue, MetricsValue }
