import { createSlice } from '@reduxjs/toolkit'
import { VesselData } from 'interfaces/vessel'

interface PerformanceState {
  selectedVessel: VesselData | null
  dateRange: {
    startTime: number
    endTime: number
  }
}

const initialState: PerformanceState = {
  selectedVessel: null,
  dateRange: {
    startTime: 0,
    endTime: 0,
  },
}

const performanceSlice = createSlice({
  name: 'performance',
  initialState,
  reducers: {
    changeSelectedVessel(state, action) {
      state.selectedVessel = action.payload
    },
    changeDateRange(state, action) {
      state.dateRange = action.payload
    },
  },
})

export const performanceReducer = performanceSlice.reducer
export const { changeSelectedVessel, changeDateRange } = performanceSlice.actions
