import {
  startOfDay,
  endOfToday,
  endOfHour,
  subDays,
  startOfToday,
  startOfISOWeek,
  endOfISOWeek,
  startOfMonth,
  endOfMonth,
  endOfDay,
  subHours,
} from 'date-fns'

function getTimePeriodData(period: string, timezoneOffset: number = 0) {
  const currentDate = new Date()
  const tzOffset = currentDate.getTimezoneOffset() * 60000 + timezoneOffset * 3600000
  const startOfCurrentDay = startOfToday()
  const endOfCurrentDayTime = endOfToday().getTime()
  const endOfPreviousHourTime = endOfHour(subHours(currentDate, 1)).getTime()

  switch (period) {
    case 'today': {
      return {
        selectedButton: 'today',
        startTime: startOfDay(currentDate).getTime() - tzOffset,
        // endTime: endOfCurrentDayTime,
        endTime: endOfHour(currentDate).getTime() - tzOffset,
      }
    }
    case 'sevenDays': {
      return {
        selectedButton: 'sevenDays',
        startTime: subDays(startOfCurrentDay, 6).getTime() - tzOffset,
        endTime: endOfPreviousHourTime - tzOffset,
      }
    }
    case 'fourteenDays': {
      return {
        selectedButton: 'fourteenDays',
        startTime: subDays(startOfCurrentDay, 13).getTime() - tzOffset,
        endTime: endOfPreviousHourTime - tzOffset,
      }
    }
    case 'month': {
      return {
        selectedButton: 'month',
        startTime: subDays(startOfCurrentDay, 29).getTime() - tzOffset,
        endTime: endOfPreviousHourTime - tzOffset,
      }
    }
    case '3months': {
      return {
        selectedButton: '3months',
        startTime: subDays(startOfCurrentDay, 89).getTime() - tzOffset,
        endTime: endOfPreviousHourTime - tzOffset,
      }
    }
    case 'year': {
      return {
        selectedButton: 'year',
        startTime: subDays(startOfCurrentDay, 365).getTime() - tzOffset,
        endTime: endOfPreviousHourTime - tzOffset,
      }
    }
    case 'previous_week': {
      const sevenDaysAgo = subDays(startOfCurrentDay, 6)
      return {
        startTime: startOfISOWeek(sevenDaysAgo).getTime() - tzOffset,
        endTime: endOfISOWeek(sevenDaysAgo).getTime() - tzOffset,
      }
    }
    case 'previous_month': {
      const oneMonthAgo = currentDate.setUTCMonth(currentDate.getUTCMonth() - 1, 1)
      return {
        startTime: startOfMonth(oneMonthAgo).getTime() - tzOffset,
        endTime: endOfMonth(oneMonthAgo).getTime() - tzOffset,
      }
    }
    case 'previous_3_months': {
      const endDate = new Date()
      const oneMonthAgo = endDate.setUTCMonth(endDate.getUTCMonth() - 1, 1)
      const threeMonthsAgo = currentDate.setUTCMonth(currentDate.getUTCMonth() - 3, 1)
      return {
        startTime: startOfMonth(threeMonthsAgo).getTime() - tzOffset,
        endTime: endOfMonth(oneMonthAgo).getTime() - tzOffset,
      }
    }
    case 'demoTime': {
      const startDate = new Date('2024-05-14')
      const endDate = new Date('2024-05-20')
      return {
        startTime: startOfDay(startDate).getTime() - tzOffset,
        endTime: endOfDay(endDate).getTime() - tzOffset,
      }
    }
  }
}

export default getTimePeriodData
