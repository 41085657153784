import './App.css'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import AppNavigator from './pages/AppNavigator'
import { useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from './firebase'
import {
  RootState,
  changeUser,
  changeAuthIsLoading,
  logout,
  changeAuthError,
  getUserCustomClaims,
  changeLineChartTickCount,
} from 'store'
import { AuthUser } from 'interfaces/auth'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { isUserExpired } from 'shared/date/isUserExpired'

function App() {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const user = useAppSelector((state: RootState) => {
    return state.auth.user
  })

  useEffect(() => {
    if (!user) return
    dispatch(getUserCustomClaims({ uid: user.uid }))
  }, [user?.uid])

  useEffect(() => {
    if (!user) return
    if (isUserExpired(user.validUntil)) {
      dispatch(changeAuthError('This account has expired.'))
      dispatch(logout())
      return
    }
  }, [location])

  useEffect(() => {
    dispatch(changeAuthIsLoading(true))
    const unsubscribe = onAuthStateChanged(auth, (user: any) => {
      if (user) {
        const authUser: AuthUser = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          companyId: null,
          isAdmin: false,
          isDemo: false,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          validUntil: 0,
        }
        dispatch(changeUser(authUser))
      } else {
        dispatch(changeUser(null))
      }
      dispatch(changeAuthIsLoading(false))
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      dispatch(changeLineChartTickCount(calculateTickCount()))
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function calculateTickCount() {
    if (window.innerWidth > 2400) {
      return 20
    } else if (window.innerWidth > 1900) {
      return 16
    } else if (window.innerWidth > 1600) {
      return 14
    } else if (window.innerWidth > 1300) {
      return 11
    } else if (window.innerWidth > 1000) {
      return 8
    } else if (window.innerWidth > 768) {
      return 6
    } else {
      return 4
    }
  }

  return (
    <div className="font-primary bg-white not-phone:h-fit min-h-screen overflow-x-hidden">
      <AppNavigator user={user} />
    </div>
  )
}

export default App
