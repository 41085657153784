export function getLineChartDatatsetHidden(
  chartLabel: string,
  datasetValues: any,
  timestamps: any,
  vesselLastUpdated: number,
  color: string,
  yAxisID: string
) {
  return {
    spanGaps: true,
    pointRadius: 0,
    type: 'line',
    label: chartLabel,
    data: datasetValues,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderWidth: 2,
    yAxisID,
  }
}
