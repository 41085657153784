import { VesselData } from 'interfaces/vessel'
import { useNavigate } from 'react-router-dom'
import ExcelService from 'services/excel-service/excel-service'
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-left.svg'
import Button from 'components/Button'
import { useAppSelector } from 'hooks/useReduxHooks'
import { RootState } from 'store'

interface Props {
  vessel: VesselData | null
}

function AnalyticsHeader({ vessel }: Props) {
  const { startTime: startDateTimeStamp, endTime: endDateTimeStamp } = useAppSelector(
    (state: RootState) => state.analytics.dateFilters
  )
  const navigate = useNavigate()
  function handleBackButtonClick() {
    navigate(`/performance-overview/${vessel?.id}`)
  }
  const { downloadReport, loading: excelLoading } = ExcelService()

  const handleDownloadReportClick = () => {
    downloadReport(vessel?.name ?? '', vessel?.id ?? '', startDateTimeStamp, endDateTimeStamp)
  }

  return (
    <div className="analytics-header flex flex-row justify-between p-4 border-b border-grayOutlineColor">
      <div className="flex items-center">
        <button
          className="h-8 w-8 bg-lighterGray rounded-full flex justify-center items-center"
          onClick={handleBackButtonClick}
        >
          <ArrowIcon fill="#0B0D14" />
        </button>
        <div className="text-xl xl:text-2xl ml-2 font-semibold">Data Analytics</div>
      </div>
      <div>
        <Button
          secondary
          disabled={excelLoading || !vessel}
          onClick={handleDownloadReportClick}
        >
          <DownloadIcon className="fill-pure-white w-5 h-5" />
          <span className="flex items-center ml-2">{excelLoading ? 'Generating report...' : 'Download report'}</span>
        </Button>
      </div>
    </div>
  )
}

export default AnalyticsHeader
