import { RootState } from 'store'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'

function CaptainReport() {
  const dispatch = useAppDispatch()
  const { startTime, endTime } = useAppSelector((state: RootState) => {
    return state.performance.dateRange
  })
  const { offset } = useAppSelector((state: RootState) => {
    return state.app.selectedTimezone
  })
  const { selectedVessel } = useAppSelector((state: RootState) => {
    return state.performance
  })

  return (
    <div className="flex flex-col gap-4 pb-6">
      <div className="mt-4">
        {/* {selectedVessel && (
          <div className="mb-8">
            <MissedSavings />
          </div>
        )} */}
      </div>
    </div>
  )
}

export default CaptainReport
