import { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import * as d3Hexbin from 'd3-hexbin'
import { createChartAxes } from './chart-components/ChartAxes'
import { createChartSections } from './chart-components/ChartSections'
import { Tooltip } from './chart-components/Tooltip'
import { createColorLegend } from './chart-components/ColorLegend'

interface DataPoint {
  nameX: string
  nameY: string
  nameZ: string
  timestamp: number | null
  valueX: number
  valueY: number
  valueZ: number
}

interface Props {
  data: DataPoint[]
  margin?: { top: number; right: number; bottom: number; left: number }
  xAxisLabel?: string
  yAxisLabel?: string
  y1AxisLabel?: string
  showChartSections?: boolean // Added boolean to show or hide chart sections
}

const HexbinChart = ({
  data,
  margin = { top: 20, right: 100, bottom: 60, left: 60 },
  xAxisLabel = 'Speed Over Ground [knots]',
  yAxisLabel = 'Fuel Consumption [kg/h]',
  y1AxisLabel = 'Frequency [minutes]',
  showChartSections = true, // Default value for showChartSections
}: Props) => {
  const svgRef = useRef<SVGSVGElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (!data || !svgRef.current || !containerRef.current || dimensions.width === 0 || dimensions.height === 0) return

    // Clear previous chart
    d3.select(svgRef.current).selectAll('*').remove()

    const { width, height } = dimensions

    // Create SVG
    const svg = d3.select(svgRef.current).attr('width', width).attr('height', height)

    // Calculate domain with extra padding for y-axis
    const maxSpeed = d3.max(data, (d) => d.valueX) || 0
    const maxFuelConsumption = d3.max(data, (d) => d.valueY) || 0
    const yAxisPadding = maxFuelConsumption * 0.1 // Add 10% to the max value

    // Create scales
    const xScale = d3
      .scaleLinear()
      .domain([0, maxSpeed])
      .range([margin.left, width - margin.right])
      .nice()

    const yScale = d3
      .scaleLinear()
      .domain([0, maxFuelConsumption + yAxisPadding]) // Add padding to domain
      .range([height - margin.bottom, margin.top])
      .nice()

    // ===== Chart Components =====

    // @ChartSections.tsx - Add background sections
    if (showChartSections) {
      createChartSections({ svg, width, height, margin, yScale })
    }

    // @ChartAxes.tsx - Add axes and grid
    createChartAxes({
      svg,
      width,
      height,
      margin,
      xScale,
      yScale,
      xAxisLabel,
      yAxisLabel,
      y1AxisLabel,
    })

    // @Tooltip.tsx - Create tooltip
    const tooltip = new Tooltip({
      container: containerRef.current,
      getValues: (d) => {
        const points = d as DataPoint[]
        const avgSpeed = d3.mean(points, (point) => point.valueX)
        const avgConsumption = d3.mean(points, (point) => point.valueY)
        return [
          { label: 'Avg Speed', value: avgSpeed || 0, unit: ' knots' },
          { label: 'Avg Consumption', value: avgConsumption || 0, unit: ' kg/h' },
          { label: 'Frequency', value: points.length },
        ]
      },
    })

    // ===== Main Chart Logic =====

    // Create hexbin generator
    const hexbin = d3Hexbin
      .hexbin<DataPoint>()
      .x((d) => xScale(d.valueX))
      .y((d) => yScale(d.valueY))
      .radius(10)
      .extent([
        [margin.left, margin.top],
        [width - margin.right, height - margin.bottom],
      ])

    // Bin the data
    const bins = hexbin(data)

    // Create color scale for hexbins based on number of points in each bin
    const colorScale = d3
      .scaleSequential()
      .domain([0, d3.max(bins, (d) => d.length) || 1])
      .interpolator(d3.interpolateViridis)

    // Add hexbins with tooltip
    const hexbins = svg
      .append('g')
      .attr('clip-path', 'url(#chart-area)') // Add clip path to prevent overflow
      .selectAll('path')
      .data(bins)
      .enter()
      .append('path')
      .attr('d', hexbin.hexagon())
      .attr('transform', (d) => `translate(${d.x},${d.y})`)
      .attr('fill', (d) => colorScale(d.length))
      .attr('stroke', '#000')
      .attr('stroke-width', '0.1')
      .attr('opacity', 1)

    // Add clip path to ensure hexbins don't exceed chart area
    svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'chart-area')
      .append('rect')
      .attr('x', margin.left)
      .attr('y', margin.top)
      .attr('width', width - margin.left - margin.right)
      .attr('height', height - margin.top - margin.bottom)

    // Add tooltip behavior
    tooltip.addTo(hexbins)

    // Add color legend
    createColorLegend({
      svg,
      width,
      height,
      margin,
      colorScale,
    })
  }, [data, dimensions, margin, xAxisLabel, yAxisLabel, y1AxisLabel, showChartSections])

  // Handle resize
  useEffect(() => {
    const updateDimensions = () => {
      if (!containerRef.current) return
      const { width, height } = containerRef.current.getBoundingClientRect()
      setDimensions({ width, height })
    }

    // Initial dimensions
    updateDimensions()

    // Add resize listener
    const resizeObserver = new ResizeObserver(updateDimensions)
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => resizeObserver.disconnect()
  }, [])

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: '100%' }}
    >
      <svg
        ref={svgRef}
        style={{ width: '100%', height: '100%' }}
      ></svg>
    </div>
  )
}

export default HexbinChart
