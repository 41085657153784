import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddUserIcon from '../assets/add_user_icon.png'
import ReusableInputField from '../components/Input'
import DotsIcons from '../assets/dots_icon.png'
import ContentWrapper from '../components/layout/ContentWrapper'
import React from 'react'
import PageHeading from 'components/typography/PageHeading'
import { RootState, useGetAllUsersQuery, useGetAllVesselsQuery, useSetUserCustomClaimsMutation } from 'store'
import Select from 'components/form/Select'
import { User } from 'interfaces/user'
import LeavePageBlocker from 'components/utility/LeavePageBlocker'
import NoData from 'components/utility/NoData'
import Button from 'components/Button'
import Loader from 'components/Loader'
import { useAppSelector } from 'hooks/useReduxHooks'

function Users() {
  const [localDataCopy, setLocalDataCopy] = useState([]) as any[]
  const [formDataChanged, setFormDataChanged] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const navigate = useNavigate()
  const authIsLoading = useAppSelector((state: RootState) => {
    return state.auth.authIsLoading
  })
  const user = useAppSelector((state: RootState) => {
    return state.auth.user
  })
  const { data: vessels, isFetching: vesselsLoading, error } = useGetAllVesselsQuery(user?.uid || '')

  const { data: userData, isFetching: usersLoading, error: usersFetchingError } = useGetAllUsersQuery()
  const [setUserCustomClaims, customClaimStatus] = useSetUserCustomClaimsMutation()

  useEffect(() => {
    if (userData) {
      setLocalDataCopy(userData)
    }
  }, [userData])

  const roleOptions = [
    { value: 'admin', label: 'Admin' },
    { value: 'user', label: 'User' },
  ]

  const handleAdminChange = async (newValue: any, targetUid: string) => {
    const newLocalDataCopy = localDataCopy.map((user: User) => {
      if (user.uid === targetUid) {
        return {
          ...user,
          is_admin: newValue.value === 'admin',
        }
      }
      return user
    })
    setLocalDataCopy(newLocalDataCopy)
    setFormDataChanged(true)
  }

  function discardChanges() {
    setLocalDataCopy(userData)
    setFormDataChanged(false)
  }

  function saveChanges() {
    localDataCopy.forEach((localUserData: User) => {
      // Compare each user with the original data and update the user if there are changes
      const originalUserData = userData!.find((originalUser: User) => originalUser.uid === localUserData.uid)
      if (originalUserData) {
        if (originalUserData.is_admin !== localUserData.is_admin) {
          setUserCustomClaims({ targetUid: localUserData.uid, isAdmin: localUserData.is_admin })
        }
      }
    })
    setFormDataChanged(false)
  }

  const filteredUserData = localDataCopy?.filter((user: User) => {
    const fullName = `${user.name} ${user.surname}`
    return (
      fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
    )
  })

  const handleSearchChange = (value: string) => {
    setSearchQuery(value)
  }
  return (
    <ContentWrapper>
      <div className="flex justify-between mb-6">
        <PageHeading>Users</PageHeading>

        {!usersLoading && !vesselsLoading && !authIsLoading && (
          <div className="ml-auto">
            <Button
              onClick={() => navigate('/users/create-user')}
              secondary
              semibold
            >
              <img
                src={AddUserIcon}
                alt="Add user icon"
                className="w-5 h-auto mr-2"
              />
              Invite people
            </Button>
          </div>
        )}
      </div>

      <div className="bg-white rounded-xl flex-grow border border-grayOutlineColor overflow-hidden">
        <div className="flex justify-between w-full h-20 border-b border-grayOutlineColor px-4 lg:px-6">
          <div className="flex justify-between items-center w-full">
            <ReusableInputField
              placeholder="Search user"
              width="256px"
              isSearchInput={true}
              value={searchQuery}
              onChange={handleSearchChange}
              className="text-sm lg:text-lg"
            />
          </div>
        </div>

        <div className="bg-[#F7F8FA] w-full h-10 border-b border-grayOutlineColor">
          <div className="flex h-full px-4 lg:px-6">
            <div className="flex-1 flex items-center h-full gap-2">
              <p className="text-grayText">Name</p>
            </div>

            <div className="flex-1 flex items-center h-full">
              <p className="text-grayText">Role</p>
            </div>

            <div className="w-1/5 flex items-center h-full">
              <p className="text-grayText">Vessels</p>
            </div>

            <div className="w-16 flex items-center h-full"></div>
          </div>
        </div>

        {usersLoading || vesselsLoading || authIsLoading || customClaimStatus.isLoading ? (
          <div className="w-full flex justify-center mt-10 mb-10 items-center">
            <Loader />
          </div>
        ) : (
          <div className="flex flex-col align-stretch flex-1 w-full h-full overflow-auto">
            <table className="w-full">
              <tbody>
                {user &&
                  filteredUserData?.map((person: User, index: number) => (
                    <React.Fragment key={index}>
                      {person.deleted_at === undefined && user.uid !== person.uid && (
                        <tr
                          key={index}
                          className="flex h-16 border-b border-grayOutlineColor px-4 lg:px-6"
                        >
                          <td className="flex-1 flex flex-col justify-center w-2/12 overflow-hidden">
                            <p className="text-sm lg:text-lg">
                              {person.name} {person.surname}
                            </p>
                            <p className="font-normal text-sm text-[#667085] whitespace-nowrap max-w-max">
                              {person.email}
                            </p>
                          </td>

                          <td className="flex-1 flex flex-col justify-center">
                            <div className="w-3/5">
                              <Select
                                options={roleOptions}
                                value={
                                  person.is_admin
                                    ? roleOptions.find(({ value }) => value === 'admin')
                                    : roleOptions.find(({ value }) => value === 'user')
                                }
                                onChange={(newValue) => handleAdminChange(newValue, person.uid)}
                              />
                            </div>
                          </td>

                          <td className="w-1/5 flex items-center ">
                            {vessels && (
                              <p>
                                {vessels.filter((vessel) => vessel.user_access?.includes(person.uid)).length} of{' '}
                                {vessels.length} vessels
                              </p>
                            )}
                          </td>

                          <td className="w-16 flex items-center justify-center">
                            <div
                              onClick={() =>
                                navigate('/user-details', {
                                  state: { userData: person },
                                })
                              }
                              className="flex h-10 w-10 items-center justify-center cursor-pointer"
                            >
                              <img
                                src={DotsIcons}
                                alt="Dots icon"
                                className="h-auto w-6"
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}

                {!filteredUserData?.length && (
                  <div className="pt-20">
                    <NoData
                      type="sad"
                      title="No Results Found"
                      text="We couldn't find any matches. Try different search terms or adjust your filters."
                    />
                  </div>
                )}
              </tbody>
            </table>

            <div className="flex flex-row justify-end items-center px-4 lg:px-6 h-20 gap-4">
              {formDataChanged && (
                <>
                  <Button
                    onClick={saveChanges}
                    secondary
                    semibold
                    style={{ width: '10rem' }}
                  >
                    Save changes
                  </Button>
                  <Button
                    onClick={discardChanges}
                    primary
                    semibold
                    style={{ width: '10rem' }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <LeavePageBlocker dirty={formDataChanged} />
    </ContentWrapper>
  )
}

export default Users
