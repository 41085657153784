import { getUTCDateString } from 'shared/date/getUTCDateString'
import { getUTCTimeString } from 'shared/date/getUTCTimeString'

export default function getLineChartScaleX(xlabels: any, lineChartTickCount: number = 10) {
  return {
    beginAtZero: true,
    ticks: {
      maxRotation: 0,
      minRotation: 0,
      autoSkip: true,
      autoSkipPadding: 30,
      maxTicksLimit: lineChartTickCount,
      callback: (value: any, index: any, values: any) => {
        const firstDateString = xlabels[0]
        const lastDateString = xlabels[xlabels.length - 1]
        const firstDate = new Date(firstDateString)
        const lastDate = new Date(lastDateString)
        const differenceInHours = Math.abs((lastDate.getTime() - firstDate.getTime()) / (1000 * 3600))
        const dateString = xlabels[index]
        const date = new Date(dateString)
        let formattedDate = ''
        let formattedTime = getUTCTimeString(date)
        if (formattedTime === '24:00') {
          formattedTime = '00:00'
        }
        if (differenceInHours > 24) {
          formattedDate = getUTCDateString(date)
        }
        if (date.getUTCMinutes() === 0) return [formattedTime, formattedDate]
      },
    },
  }
}
