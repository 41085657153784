import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ChartAlignmentData {
  chartAreaWidth: number
}

interface PerformanceOverviewAlignment {
  ShaftSpeedAndPower: { chartAreaWidth: number }
  FuelConsumption: { chartAreaWidth: number }
  Speed: { chartAreaWidth: number }
  //   FuelConsumptionVsSpeed: { chartAreaWidth: number }
}

interface LineChartState {
  performanceOverviewAlignment: PerformanceOverviewAlignment
  performanceOverviewAlignmentDone: boolean
}

const initialState: LineChartState = {
  performanceOverviewAlignment: {
    ShaftSpeedAndPower: {
      chartAreaWidth: 0,
    },
    FuelConsumption: {
      chartAreaWidth: 0,
    },
    Speed: {
      chartAreaWidth: 0,
    },
    // FuelConsumptionVsSpeed: {
    //   chartAreaWidth: 0,
    // },
  },
  performanceOverviewAlignmentDone: false,
}

const lineChartSlice = createSlice({
  name: 'lineChart',
  initialState,
  reducers: {
    changeLineChartAreaWidth(
      state,
      action: PayloadAction<{ chartName: keyof PerformanceOverviewAlignment; chartAreaWidth: number }>
    ) {
      if (state.performanceOverviewAlignmentDone === false) {
        const { chartName, chartAreaWidth } = action.payload
        state.performanceOverviewAlignment[chartName].chartAreaWidth = chartAreaWidth
      }
    },
    changePerformanceOverviewAlignmentDone(state, action) {
      state.performanceOverviewAlignmentDone = action.payload
    },
    resetLineChartState() {
      return initialState
    },
  },
})

export const lineChartReducer = lineChartSlice.reducer
export const { changeLineChartAreaWidth, changePerformanceOverviewAlignmentDone, resetLineChartState } =
  lineChartSlice.actions
