import {
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Legend,
  CategoryScale,
  Tooltip as ChartTooltip,
} from 'chart.js'
import { VesselData } from 'interfaces/vessel'
import { Chart } from 'react-chartjs-2'
import { changeChartIsRendering, RootState, useGetMassFlowRateInKnotsQuery } from 'store'
import NoData from 'components/utility/NoData'
import Loader from 'components/Loader'
import { useEffect, useState } from 'react'
import { Scales } from 'interfaces/chart'
import ChartWrapper from 'components/chart/ChartWrapper'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import scatterChartWorker from 'workers/scatterChartWorker'

ChartJS.register(LinearScale, LineElement, PointElement, CategoryScale, Legend, ChartTooltip)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
}

function FuelConsumptionVsSpeed({ vessel, startTime, endTime }: Props) {
  const dispatch = useAppDispatch()
  const [scatterData, setScatterData] = useState<any>(null)
  const dataAvailable = true // TODO: check if data is available (probably need changes in backend)
  const chartIsRendering = useAppSelector((state: RootState) => state.app.chartIsRendering)

  const { data, isFetching } = useGetMassFlowRateInKnotsQuery({
    vesselId: vessel.id,
    flowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
    startTime: startTime,
    endTime: endTime,
    isSplit: vessel.main_engines[0].flowMeter[0].split,
  })

  let chartDataAndOptions = null
  if (scatterData) {
    const datasets: any = []
    if (data?.scatterData?.length) {
      datasets.push({
        type: 'scatter',
        label: 'Scatter Dataset',
        data: scatterData?.graph1ScatterData,
        backgroundColor: 'transparent',
        pointRadius: 0,
        yAxisID: 'y',
      })
    }
    if (data?.trendLine?.length) {
      datasets.push({
        type: 'line',
        label: 'Speed Over Ground [knots]',
        data: scatterData?.graph1TrendlineData,
        backgroundColor: '#118F1E',
        borderColor: '#118F1E',
        pointRadius: 0,
        pointHitRadius: 0,
        yAxisID: 'y',
      })
    }

    const scales: Scales = {}
    if (data?.scatterData) {
      scales.y = {
        type: 'linear' as const,
        position: 'left' as const,
        title: {
          display: true,
          text: 'M/E Consumption [kg/h]',
          font: {
            size: 16,
          },
        },
      }
    }

    chartDataAndOptions = {
      chartOptions: {
        animation: false,
        normalized: true,
        maintainAspectRatio: false,
        chartInstanceId: '',
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            position: 'bottom' as const,
            labels: {
              boxWidth: 12,
              boxHeight: 12,
              usePointStyle: true,
              pointStyle: 'rectRounded',
              padding: 20,
              font: {
                size: 16,
              },
              filter: function (item: any, chart: any) {
                return item.text !== 'Scatter Dataset'
              },
            },
          },
        },
        scales,
      } as any,
      chartData: {
        datasets,
      },
    }
  }

  const loading = chartIsRendering || isFetching || !chartDataAndOptions

  useEffect(() => {
    setScatterData(null)
  }, [startTime, endTime])

  useEffect(() => {
    if (isFetching) return
    const worker = new Worker(scatterChartWorker)
    worker.postMessage({ graph1: data, graph2: null })
    worker.onmessage = (e: MessageEvent) => {
      setScatterData(e.data)
      // This chart with only one dataset renders faster than other charts,
      // therefore we should not let this chart stop loading for other charts (as chartIsRendering is global)
      // dispatch(changeChartIsRendering(false))
    }
    return () => {
      worker.terminate()
    }
  }, [data])

  return (
    <ChartWrapper
      title="M/E Consumption [kg/h] vs Speed [knots]"
      tooltipText="Relationship between ME Consumption and Speed based on selected interval"
      tooltipId="fc-vs-speed-tooltip"
      className="h-96 FuelConsumptionVsSpeed"
    >
      {loading || !chartDataAndOptions ? (
        <Loader />
      ) : !dataAvailable ? (
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      ) : (
        <Chart
          options={chartDataAndOptions.chartOptions}
          type="line"
          data={chartDataAndOptions.chartData}
        ></Chart>
      )}
    </ChartWrapper>
  )
}

export default FuelConsumptionVsSpeed
