import { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { createChartAxes } from './chart-components/ChartAxes'
import { createChartSections } from './chart-components/ChartSections'
import { Tooltip } from './chart-components/Tooltip'
import { createColorLegend } from './chart-components/ColorLegend'

interface DataPoint {
  nameX: string
  nameY: string
  nameZ: string
  timestamp: number | null
  valueX: number
  valueY: number
  valueZ: number
}

interface Props {
  data: DataPoint[]
  margin?: { top: number; right: number; bottom: number; left: number }
  xAxisLabel?: string
  yAxisLabel?: string
  y1AxisLabel?: string
  showChartSections?: boolean
}

const ScatterChart = ({
  data,
  margin = { top: 20, right: 100, bottom: 60, left: 60 },
  xAxisLabel = 'Speed Over Ground [knots]',
  yAxisLabel = 'Fuel Consumption [kg/h]',
  y1AxisLabel = 'Fuel Efficiency [kg/nm',
  showChartSections = true,
}: Props) => {
  const svgRef = useRef<SVGSVGElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  // Handle resize
  useEffect(() => {
    const updateDimensions = () => {
      if (!containerRef.current) return
      const { width, height } = containerRef.current.getBoundingClientRect()
      setDimensions({ width, height })
    }

    // Initial dimensions
    updateDimensions()

    // Add resize listener
    const resizeObserver = new ResizeObserver(updateDimensions)
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => resizeObserver.disconnect()
  }, [])

  useEffect(() => {
    if (!data || !svgRef.current || !containerRef.current || dimensions.width === 0 || dimensions.height === 0) return

    // Clear previous chart
    d3.select(svgRef.current).selectAll('*').remove()

    const { width, height } = dimensions

    // Create SVG
    const svg = d3.select(svgRef.current).attr('width', width).attr('height', height)

    // Create scales
    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.valueX) || 0])
      .range([margin.left, width - margin.right])
      .nice()

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.valueY) || 0])
      .range([height - margin.bottom, margin.top])
      .nice()

    // Create color scale
    const colorScale = d3
      .scaleSequential()
      .domain([d3.min(data, (d) => d.valueZ) || 0, d3.max(data, (d) => d.valueZ) || 0])
      .interpolator(d3.interpolateViridis)

    // ===== Chart Components =====

    // @ChartSections.tsx - Add background sections
    if (showChartSections) {
      createChartSections({ svg, width, height, margin, yScale })
    }

    // @ChartAxes.tsx - Add axes and grid
    createChartAxes({
      svg,
      width,
      height,
      margin,
      xScale,
      yScale,
      xAxisLabel,
      yAxisLabel,
      y1AxisLabel,
    })

    // @ColorLegend.tsx - Add color legend
    createColorLegend({
      svg,
      width,
      height,
      margin,
      colorScale,
    })

    // ===== Main Chart Logic =====

    // Add scatter points with tooltips
    svg
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', (d) => xScale(d.valueX))
      .attr('cy', (d) => yScale(d.valueY))
      .attr('r', 3)
      .attr('fill', (d) => colorScale(d.valueZ))
      .attr('opacity', 1)
  }, [data, dimensions, margin, xAxisLabel, yAxisLabel, y1AxisLabel, showChartSections])

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: '100%' }}
    >
      <svg
        ref={svgRef}
        style={{ width: '100%', height: '100%' }}
      ></svg>
    </div>
  )
}

export default ScatterChart
